/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import Downshift from 'downshift';
import { ChevronDownIcon } from '@heroicons/react/outline';
import Link from 'next/link';

type Props = {
  label: string;
  items: {
    id: string;
    slug: string;
    value: string;
  }[];
};

const GenresSelect: React.FC<Props> = ({ label, items }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleStateChange = React.useCallback((state) => {
    if (state.isOpen !== undefined) {
      setIsOpen(false);
    }
  }, []);

  const handleSelectClick = React.useCallback(
    () => setIsOpen(!isOpen),
    [isOpen],
  );

  const handleOptionClick = React.useCallback(() => setIsOpen(false), []);

  return (
    <Downshift
      isOpen={isOpen}
      itemToString={(item) => (item ? item.value : '')}
      onStateChange={handleStateChange}
    >
      {({ isOpen }) => (
        <div className="relative">
          <div
            className="flex justify-between items-center h-full bg-transparent text-sm cursor-pointer focus:ring-0 overflow-ellipsis space-x-1"
            onClick={handleSelectClick}
          >
            <div className="lg:text-xs">{label}</div>
            <ChevronDownIcon className={`w-5 text-white`} />
          </div>

          {isOpen ? (
            <ul className="absolute left-0 top-0 mt-12 bg-black bg-opacity-70 rounded-md px-4 py-3 w-60 space-y-3 z-50">
              {items.map((item) => (
                <li
                  key={item.id}
                  className="cursor-pointer"
                  onClick={handleOptionClick}
                >
                  <Link href={`/genres/${item.slug}`}>{item.value}</Link>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      )}
    </Downshift>
  );
};

export default GenresSelect;
