export const layoutRegisterLogin = [
  '/login',
  '/link',
  '/register',
  '/reset-password',
  '/reset-password/[token]',
  '/verification',
  '/verify-email/[token]',
];

export const unavilableRoutes = ['/unavailable'];
export const textRoutes = ['/ads.txt', '/app-ads.txt', '/sellers.json'];
