const Facebook = ({ className }) => (
  <svg
    className={className}
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.7">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 0C5.42487 0 0.5 4.92487 0.5 11C0.5 17.0751 5.42487 22 11.5 22C17.5751 22 22.5 17.0751 22.5 11C22.5 4.92487 17.5751 0 11.5 0ZM12.3538 11.4896V16.4983H10.3298V11.4859H8.64741V9.52488H10.3261V8.08571C10.3261 6.41188 11.3516 5.5001 12.8451 5.5001C13.3487 5.49814 13.852 5.52405 14.3527 5.57771V7.3261H13.3236C12.5115 7.3261 12.3538 7.71355 12.3538 8.27944V9.52855H14.2947L14.0435 11.4896H12.3538Z"
        fill="white"
      />
    </g>
  </svg>
);

export default Facebook;
