export const APP_ENVIRONMENT = process.env.NEXT_PUBLIC_APP_ENV;
export const PROTECT_PAGE =
  'e276bff7792ced7d598a7fc9b931cc35bd795365f1ef599f7778a33c14eeeded7de784d0655f4e1cf6b5171deada1c59fd810183fa05180590b484cb08d89ed9';
export const APPSFLYER_WEB_DEV_KEY =
  process.env.NEXT_PUBLIC_APPSFLYER_WEB_DEV_KEY;
export const ROKU_TV_STORE_URL =
  'https://channelstore.roku.com/details/587772/butaca-tv';
export const FIRE_TV_STORE_URL =
  'https://www.amazon.com/Veranda-Entertainment-Butaca-TV/dp/B082DN8PW4';
export const GOOGLE_PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.butacatv.butaca_app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';
export const APP_STORE_URL =
  'https://apps.apple.com/us/app/butaca-tv-watch-movies/id1543956774?ign-itsct=apps_box&ign-itscg=30200';
