import { IncomingMessage } from 'http';

const { NEXT_PUBLIC_API_URL } = process.env;
let API_TOKEN = '';
const Api = {
  registerToken(token: string): void {
    API_TOKEN = token || '';
  },
  async getRaw(endpoint: string, req?: IncomingMessage): Promise<any> {
    let forwardedIp;

    if (req) {
      forwardedIp = req.headers['x-forwarded-for'];
    }
    const headers = {
      'Content-Type': 'application/json',
      'X-Device-User-Agent': 'web',
      'X-Brand': 'Butaca',
      'X-Auth-Token': API_TOKEN,
    };

    if (forwardedIp) {
      headers['X-Forwarded-For'] = forwardedIp;
      headers['X-Real-Ip'] = forwardedIp;
      headers['User-Agent'] = req.headers['user-agent'];
    }

    const response = await fetch(`${NEXT_PUBLIC_API_URL}/${endpoint}`, {
      method: 'GET',
      credentials: 'same-origin',
      headers: headers,
    });
    if (response.status <= 199 || response.status >= 300) {
      return Promise.reject(response);
    }
    return response.text();
  },
  async get(
    endpoint: string,
    req?: IncomingMessage,
    deviceUserAgent?: string,
  ): Promise<any> {
    let forwardedIp;

    if (req) {
      forwardedIp = req.headers['x-forwarded-for'];
    }

    const headers = {
      'Content-Type': 'application/json',
      'X-Device-User-Agent': 'web',
      'X-Brand': 'Butaca',
      'X-Auth-Token': API_TOKEN,
    };

    if (deviceUserAgent) {
      headers['X-Device-User-Agent'] = deviceUserAgent;
    }

    if (forwardedIp) {
      headers['X-Forwarded-For'] = forwardedIp;
      headers['X-Real-Ip'] = forwardedIp;
      headers['User-Agent'] = req.headers['user-agent'];
      console.log('realip', req.headers['x-real-ip']);
      console.log('forwardedfor', req.headers['x-forwarded-for']);
      console.log('vercelforwardedfor', req.headers['x-vercel-forwarded-for']);
      console.log('x-vercel-ip-country', req.headers['x-vercel-ip-country']);
    }

    const response = await fetch(`${NEXT_PUBLIC_API_URL}/${endpoint}`, {
      method: 'GET',
      cache: 'no-store',
      credentials: 'same-origin',
      headers: headers,
    });

    if (response.status <= 199 || response.status >= 300) {
      return Promise.reject(response);
    }
    return response.json();
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async post(endpoint: string, data?: any): Promise<any> {
    const response = await fetch(`${NEXT_PUBLIC_API_URL}/${endpoint}`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        ...(data ? { 'Content-Type': 'application/json' } : {}),
        'X-Device-User-Agent': 'web',
        'X-Brand': 'Butaca',
        'X-Auth-Token': API_TOKEN,
      },
      ...(data ? { body: JSON.stringify(data) } : {}),
    });
    if (response.status <= 199 || response.status >= 300) {
      return Promise.reject(response);
    }
    return response.json();
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async put(endpoint: string, data?: any): Promise<any> {
    const response = await fetch(`${NEXT_PUBLIC_API_URL}/${endpoint}`, {
      method: 'PUT',
      credentials: 'same-origin',
      headers: {
        ...(data ? { 'Content-Type': 'application/json' } : {}),
        'X-Device-User-Agent': 'web',
        'X-Brand': 'Butaca',
        'X-Auth-Token': API_TOKEN,
      },
      ...(data ? { body: JSON.stringify(data) } : {}),
    });
    if (response.status <= 199 || response.status >= 300) {
      return Promise.reject(response);
    }
    return response.json();
  },
  async delete(endpoint: string): Promise<any> {
    const response = await fetch(`${NEXT_PUBLIC_API_URL}/${endpoint}`, {
      method: 'DELETE',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-Device-User-Agent': 'web',
        'X-Brand': 'Butaca',
        'X-Auth-Token': API_TOKEN,
      },
    });
    if (response.status <= 199 || response.status >= 300) {
      return Promise.reject(await response.json());
    }
    return response.json();
  },
};

export default Api;
