import { Language } from '@types';
import React from 'react';
import { useRouter } from 'next/router';
import Facebook from '@/icons/Facebook';
import Twitter from '@/icons/Twitter';
import Instagram from '@/icons/Instagram';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

const Footer: React.FC = () => {
  const router = useRouter();
  const { t } = useTranslation();

  return (
    <div className="border-t border-white border-opacity-10 flex">
      <div className="container mx-auto px-4 xl:px-0">
        <div className="flex flex-col lg:flex-row w-full justify-between py-12 lg:py-20 space-y-12 lg:space-y-0 font-medium text-sm">
          <div className="space-y-3 text-center lg:text-left order-1 lg:order-6">
            <div className="uppercase lg:pr-12">{t('footer.languages')}</div>
            <div className="flex-1">
              <select
                className="l w-1/3 lg:w-full text-sm"
                onChange={(evt) => {
                  router.push(
                    { pathname: router.pathname, query: router.query },
                    undefined,
                    { locale: evt.currentTarget.value },
                  );
                }}
                defaultValue={router.locale}
              >
                <option value={Language.EN}>English</option>
                <option value={Language.ES}>Español</option>
              </select>
            </div>
          </div>
          <div className="space-y-3 text-center lg:text-left order-2">
            <div className="uppercase">{t('footer.company')}</div>
            <div className="text-sm text-secondary">
              <ul className="space-y-3 lg:text-xs">
                <li>
                  <Link href="/pages/about">{t('footer.aboutUs')}</Link>
                </li>
                <li>
                  <Link href="/contact-us">{t('footer.contactUs')}</Link>
                </li>
                <li>
                  <Link href="/report">{t('footer.technicalReport')}</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="space-y-3 text-center lg:text-left order-3">
            <div className="uppercase">{t('footer.support')}</div>
            <div className="text-sm text-secondary">
              <ul className="space-y-3 lg:text-xs">
                <li>
                  <Link href="/reloj">{t('footer.whereToWatch')}</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="space-y-3 text-center lg:text-left order-4">
            <div className="uppercase">{t('footer.partners')}</div>
            <div className="text-sm text-secondary">
              <ul className="space-y-3 lg:text-xs">
                <li>
                  <Link href="/pages/for-advertisers">
                    {t('footer.forAdvertisers')}
                  </Link>
                </li>
                <li>
                  <Link href="/content">{t('footer.submitContent')}</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="space-y-3 text-center lg:text-left order-5">
            <div className="uppercase">{t('footer.legal')}</div>
            <div className="text-sm text-secondary">
              <ul className="space-y-3 lg:text-xs">
                <li>
                  <Link href="/pages/aviso-de-privacidad">
                    {t('footer.privacyPolicy')}
                  </Link>
                </li>
                <li>
                  <Link href="/pages/terms-of-use">
                    {t('footer.termsOfUse')}
                  </Link>
                </li>
                <li>
                  <button id="ot-sdk-btn">
                    {t('footer.consentPreferences')}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 text-center items-center justify-between pb-12 text-secondary">
          <div className="flex justify-center space-x-4 order-1 lg:order-3">
            <a
              target="_blank"
              title="facebook"
              rel="noreferrer"
              href="https://www.facebook.com/ButacaTV"
            >
              <Facebook className="cursor-pointer w-8 h-8" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              title="twitter"
              href="https://x.com/butacatv"
            >
              <div className="bg-[#b3b3b3] w-8 h-8 rounded-full text-center flex items-center justify-center">
                <Twitter className="cursor-pointer w-5 h-5 text-black" />
              </div>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              title="instagram"
              href="https://www.instagram.com/butacatv"
            >
              <Instagram className="cursor-pointer w-8 h-8" />
            </a>
          </div>
          <div className="flex flex-col lg:flex-row items-center space-y-4 lg:space-y-0 space-x-0 lg:space-x-2 order-2 font-light text-sm">
            <div>Copyright @{new Date().getFullYear()} Butaca, Inc.</div>
            {router.locale === Language.EN ? (
              <div>
                Butaca is a registered trademark of Veranda Ent. Inc. All right
                are reserved
              </div>
            ) : (
              <div>
                Butaca es una marca registrada de Veranda Ent. Inc. Todos los
                derechos reservados.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
