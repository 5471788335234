import React from 'react';
import { ChannelContextType } from '@types';
import { useState } from 'react';

type Props = {
  children: React.ReactChild;
};

export const ChannelContext = React.createContext<ChannelContextType>(null);

export const ChannelProvider: React.FC<Props> = ({ children }: Props) => {
  const [nowPlaying, setNowPlaying] = useState();
  const [mainPlaylist, setMainPlaylist] = useState();
  const [playlists, setPlaylists] = useState([]);

  return (
    <ChannelContext.Provider
      value={{
        nowPlaying,
        playlists,
        mainPlaylist,
        setNowPlaying,
        setPlaylists,
        setMainPlaylist,
      }}
    >
      {children}
    </ChannelContext.Provider>
  );
};
