import React, { useContext } from 'react';
import { SearchIcon } from '@heroicons/react/outline';
import { AppContext } from '@/contexts/app.context';
import { NavbarKey } from '@types';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useQuery } from 'react-query';
import Api from '@/services/Api';

const NavigationBar: React.FC = () => {
  const { currentNavbarKey } = useContext(AppContext);
  const { t } = useTranslation();

  const { data: remezclaStatus } = useQuery(
    'remezclaStatus',
    () => Api.get('v2/tabs/status/remezcla'),
    {
      staleTime: 60000 * 5,
      retry: 1,
      refetchOnWindowFocus: false,
    },
  );

  const remezclaVisible = remezclaStatus?.isVisible ?? true;

  return (
    <div>
      <ul className="flex xl:space-x-4 2xl:space-x-6 3xl:space-x-8 bg-black bg-opacity-80 rounded-full items-center text-sm lg:text-xs font-medium">
        <li
          className={`${
            currentNavbarKey === NavbarKey.BROWSE
              ? 'NavbarSelectedItem'
              : 'NavbarItem'
          }`}
        >
          <Link href="/">{t('browse')}</Link>
        </li>
        <li
          className={`${
            currentNavbarKey === NavbarKey.MOVIES
              ? 'NavbarSelectedItem'
              : 'NavbarItem'
          }`}
        >
          <Link href="/movies">{t('movies')}</Link>
        </li>
        <li
          className={`${
            currentNavbarKey === NavbarKey.TV_SHOW
              ? 'NavbarSelectedItem'
              : 'NavbarItem'
          }`}
        >
          <Link href="/tv_show">{t('tvShow')}</Link>
        </li>
        <li
          className={`${
            currentNavbarKey === NavbarKey.CHANNELS
              ? 'NavbarSelectedItem'
              : 'NavbarItem'
          }`}
        >
          <Link href="/channels">{t('channels')}</Link>
        </li>
        {/* Conditionally render the Remezcla tab */}
        {remezclaVisible && (
          <li
            className={`${
              currentNavbarKey === NavbarKey.MUSIC
                ? 'NavbarSelectedItem'
                : 'NavbarItem'
            }`}
          >
            <Link href="/remezcla">{t('music')}</Link>
          </li>
        )}
        <li className="px-6 cursor-pointer">
          <Link href="/search">
            <SearchIcon className="text-white w-5 h-5" />
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default NavigationBar;
