import React, { ReactElement } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import MobileMenu from '../MobileMenu/MobileMenu';

type Props = {
  floatHeader: boolean;
  children: ReactElement | ReactElement[];
};

const Layout: React.FC<Props> = ({ floatHeader, children }: Props) => {
  return (
    <div>
      <Header float={floatHeader} />
      <main>{children}</main>
      <MobileMenu />
      <Footer />
    </div>
  );
};

export default Layout;
