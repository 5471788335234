/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import React, { useEffect } from 'react';
import Head from 'next/head';
import App from 'next/app';
import dynamic from 'next/dynamic';
import type { AppContext, AppProps } from 'next/app';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { Session } from 'next-auth';
import { getSession, Provider } from 'next-auth/client';
import { QueryClientProvider, QueryClient } from 'react-query';
import { AppProvider } from '@/contexts/app.context';
import { ChannelProvider } from '@/contexts/channel.context';
import { useLayout } from '@/hooks/useLayout';
import { ReactQueryDevtools } from 'react-query/devtools';
import { appWithTranslation } from 'next-i18next';
import Api from '@/services/Api';
import { isMobile } from 'react-device-detect';
import { GA_TRACKING_ID, GA_PIXEL_ID } from '@/utils/gtag';
import { FB_PIXEL_ID, pageview } from '@/utils/fpixel';
import { APPSFLYER_WEB_DEV_KEY } from '@/utils/constants';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: process.env.NEXT_PUBLIC_GTM_ID,
};

import 'plyr/dist/plyr.css';
import 'react-app-protect/dist/index.css';

import '../styles/globals.css';
import '../styles/static.css';

const NextNProgress = dynamic(() => import('nextjs-progressbar'), {
  ssr: false,
});

const Toaster = dynamic(
  () => import('react-hot-toast').then((mod) => mod.Toaster),
  {
    ssr: false,
  },
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const MyApp = ({
  pageProps,
  Component,
  session,
}: AppProps & { session: Session }) => {
  const Layout = useLayout();
  const router = useRouter();

  useEffect(() => {
    // This fpixel.pageview only triggers the first time (it's important for Pixel to have real information)
    pageview();

    const handleRouteChange = () => {
      pageview();
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  if (process.browser) {
    Api.registerToken(session?.accessToken as string);
  }

  return (
    <>
      {GA_TRACKING_ID && (
        <Script
          src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
          onLoad={() => {
            // @ts-ignore
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              // @ts-ignore
              dataLayer.push(arguments);
            }
            // @ts-ignore
            gtag('js', new Date());
            // @ts-ignore
            gtag('config', `'${GA_TRACKING_ID}'`);
          }}
        />
      )}
      {GA_PIXEL_ID && (
        <Script
          src={`https://www.googletagmanager.com/gtag/js?id=${GA_PIXEL_ID}`}
          onLoad={() => {
            // @ts-ignore
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              // @ts-ignore
              dataLayer.push(arguments);
            }
            // @ts-ignore
            gtag('js', new Date());
            // @ts-ignore
            gtag('config', `'${GA_PIXEL_ID}'`);
            // @ts-ignore
            gtag('event', 'conversion', {
              send_to: 'AW-1069558167/1dbpCM2jw4QYEJfTgP4D',
            });
          }}
        />
      )}
      {FB_PIXEL_ID && (
        <Script
          id="fb-pixel"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${FB_PIXEL_ID});
          `,
          }}
        />
      )}
      {APPSFLYER_WEB_DEV_KEY && (
        <Script
          id="appsflyer-sdk"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            !function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){
            (t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},
            t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,
            o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),
            p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","pba",{pba: {webAppId: "${APPSFLYER_WEB_DEV_KEY}"}})
          `,
          }}
        />
      )}
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>
      <NextNProgress
        color="#D53527"
        startPosition={0.55}
        stopDelayMs={200}
        height={2}
        showOnShallow={true}
        options={{
          showSpinner: !isMobile,
        }}
      />
      <QueryClientProvider client={queryClient}>
        <Provider session={session}>
          <AppProvider>
            <ChannelProvider>
              <Layout>
                <Toaster />
                <Component {...pageProps} />
              </Layout>
            </ChannelProvider>
          </AppProvider>
        </Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
};

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.

MyApp.getInitialProps = async (appContext: AppContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);
  const { req } = appContext.ctx;
  const session = await getSession({ req });
  Api.registerToken(session?.accessToken as string);
  return { ...appProps, session };
};

export default appWithTranslation(MyApp);
