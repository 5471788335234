import dynamic from 'next/dynamic';
import { PATH_PAGE } from '../routes/paths';

const Protect = dynamic(() => import('react-app-protect'), {
  ssr: false,
});
import { useRouter } from 'next/router';
import Layout from '@/components/Layout/Layout';
import LoginRegisterLayout from '@/components/LoginRegisterLayout/LoginRegisterLayout';
import {
  layoutRegisterLogin,
  unavilableRoutes,
  textRoutes,
} from '@/constants/layout';
import { PROTECT_PAGE } from '@/utils/constants';
export const useLayout = () => {
  const router = useRouter();
  let FinalLayout = ({ children }) => (
    <Layout
      floatHeader={[
        PATH_PAGE.root,
        PATH_PAGE.movies,
        PATH_PAGE.tv_show,
        PATH_PAGE.channels,
        PATH_PAGE.music,
      ].includes(router.pathname)}
    >
      {children}
    </Layout>
  );

  if (process.env.NEXT_PUBLIC_APP_ENV === 'staging') {
    FinalLayout = ({ children }) => (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <Protect sha512={PROTECT_PAGE}>
        <Layout
          floatHeader={[
            PATH_PAGE.root,
            PATH_PAGE.movies,
            PATH_PAGE.tv_show,
            PATH_PAGE.channels,
            PATH_PAGE.music,
          ].includes(router.pathname)}
        >
          {children}
        </Layout>
      </Protect>
    );
  }

  if (layoutRegisterLogin.includes(router.pathname)) {
    const passwordReset = '/reset-password/[token]' == router.pathname;

    FinalLayout = ({ children }) => (
      <LoginRegisterLayout
        passwordReset={passwordReset}
        isLogin={
          router.pathname === '/login' || router.pathname === '/reset-password'
        }
      >
        {children}
      </LoginRegisterLayout>
    );
  }

  if (unavilableRoutes.includes(router.pathname)) {
    FinalLayout = ({ children }) => <>{children}</>;
  }

  if (textRoutes.includes(router.pathname)) {
    FinalLayout = ({ children }) => <>{children}</>;
  }

  return FinalLayout;
};
