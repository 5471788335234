import { AppContext } from '@/contexts/app.context';
import { SearchIcon } from '@heroicons/react/outline';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';

type Props = {
  isLoading?: boolean;
};

const SearchBox: React.FC<Props> = ({ isLoading }: Props) => {
  const { t } = useTranslation('common');
  const { setSearchCriteria } = useContext(AppContext);
  const { register, handleSubmit } = useForm();
  const onSubmit = ({ keyword }) => {
    if (!isLoading) {
      if (keyword.length > 2) {
        setSearchCriteria(keyword);
      }
    }
  };

  return (
    <form
      className="hidden lg:flex lg:flex-grow justify-center"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex text-white items-center bg-transparent bg-opacity-10 rounded-3xl w-full lg:w-5/6 border border-opacity-10">
        <input
          className="mx-4 py-3 border-none focus:ring-0 w-full placeholder-white text-base lg:text-sm font-medium bg-transparent opacity-80"
          type="text"
          placeholder={t('search.placeholder')}
          {...register('keyword')}
        />
        <button
          type="submit"
          className="hidden lg:flex items-center justify-center bg-accent rounded-3xl h-full w-20 cursor-pointer"
        >
          <SearchIcon className="text-white w-6 h-6" />
        </button>
      </div>
    </form>
  );
};

export default SearchBox;
