/* eslint-disable @typescript-eslint/ban-ts-comment */
const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID;
const GA_PIXEL_ID = process.env.NEXT_PUBLIC_GA_PIXEL_ID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
const pageview = (url) => {
  // @ts-ignore
  if (window.gtag) {
    // @ts-ignore
    window.gtag('config', GA_TRACKING_ID, {
      page_path: url,
    });
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
const event = ({ event, data }: { event: string; data: any }): any => {
  // @ts-ignore
  if (window?.dataLayer) {
    // @ts-ignore
    window.dataLayer.push({ event, data });
  }
};

export { GA_TRACKING_ID, GA_PIXEL_ID, pageview, event };
