import React, { ReactElement, useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import Logo from '../../public/static/svg/logo.svg';
import LogoRegisterPic from '../../public/static/img/login_register.png';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import { XIcon } from '@heroicons/react/outline';
import { useRouter } from 'next/router';
import { Language } from '../../@types';

type Props = {
  isLogin: boolean;
  passwordReset: boolean;
  children: ReactElement | ReactElement[];
};

const LoginRegisterLayout: React.FC<Props> = ({
  isLogin,
  children,
  passwordReset,
}: Props) => {
  const [query, setQuery] = useState(null);
  const { t } = useTranslation('common');
  const router = useRouter();
  const handleIcon = () => router.push('/');

  useEffect(() => {
    setQuery(router.query);
  }, []);

  return (
    <main className="flex w-full">
      <div className="relative hidden xl:flex flex-col justify-center xl:w-3/6 2xl:w-4/6 h-screen overflow-hidden">
        {/* Overlay div */}
        <div className="absolute bottom-0 left-0 right-0 top-0 bg-black bg-opacity-30 z-10" />
        <div>
          <div className="hidden lg:block">
            <Link
              className="absolute z-50 top-4 left-4 p-2 flex justify-between items-center"
              href="/"
            >
              <ArrowLeftIcon className="w-5 h-5 m-2" />
              <p className="font-semibold">{t('common:backToHome')}</p>
              <div />
            </Link>
          </div>
          <Image
            layout="fill"
            objectFit="cover"
            src={LogoRegisterPic}
            alt="Login / Register"
            placeholder="blur"
            priority
            quality={90}
          />
        </div>
      </div>
      <div className="flex flex-col flex-grow items-center px-4 md:px-8 pt-6 lg:pt-12 overflow-y-auto h-screen overflow-hidden">
        <div className="w-full flex items-center lg:hidden justify-end mb-4">
          <select
            className="l w-1/3 lg:w-full text-sm mr-2"
            onChange={(evt) => {
              router.push(
                { pathname: router.pathname, query: router.query },
                undefined,
                { locale: evt.currentTarget.value },
              );
            }}
            defaultValue={router.locale}
          >
            <option value={Language.EN}>English</option>
            <option value={Language.ES}>Español</option>
          </select>
          <XIcon onClick={handleIcon} className="text-white w-8 h-8" />
        </div>
        {!passwordReset && (
          <>
            <Link className="flex justify-center" href="/">
              <Image
                src={Logo}
                layout="intrinsic"
                width="250"
                height="86"
                alt="Butaca Tv"
              />
            </Link>
            <ul className="w-full flex text-center my-4">
              <li
                className={`transition flex-grow border-b-2  py-3 ${
                  isLogin
                    ? 'border-accent'
                    : 'border-white border-opacity-10 opacity-50'
                }`}
              >
                <Link href={{ pathname: '/login', query: query }}>
                  {t('login')}
                </Link>
              </li>
              <li
                className={`transition flex-grow border-b-2  py-3 ${
                  !isLogin
                    ? 'border-accent'
                    : 'border-white border-opacity-10 opacity-50'
                }`}
              >
                <Link href={{ pathname: '/register', query }}>
                  {t('register')}
                </Link>
              </li>
            </ul>
          </>
        )}
        {children}
      </div>
    </main>
  );
};

export default LoginRegisterLayout;
